import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, FormControl, FormGroup } from "react-bootstrap";
import Content from "../../blocks/Content";
import AddStudentModal from "./modals/AddStudentModal";
import ConfirmStudentModal from "./modals/ConfirmStudentModal";
import SearchStudentModal from "./modals/SearchStudentModal";

export default function ElectronicClass(props) {
  const [searchString, setSearchString] = useState('');
  const [showAddStudentModal, setShowAddStudentModal] = useState(false);
  const [showSelectedStudent, setShowSelectedStudent] = useState(false);
  const [showSearchStudent, setShowSearchStudent] = useState(false);
  const students = props.students;
  const course_title = props.free_course
    ? props.t('Training session with free choice of course')
    : `${props.t('Course training session')} ${props.course?.title ? props.course.title : ''}`;
  const students_list = [];
  let list = <></>;

  const handleChangeStudents = () => students.map(el => {
      if (searchString !== '') {
        if (searchString.toLowerCase() === el.student.title.slice(0, searchString.length).toLowerCase()) students_list.push(el);
      } else {
        students_list.push(el);
      }
    });

  const selectStudent = (student) => {
    props.handleSelectStudent(student);
    setShowSelectedStudent(true);
  };

  const showAddFromSearch = () => {
    setShowSearchStudent(false);
    setShowAddStudentModal(true);
  };
  
  const handleShowAddStudentModal = () => {
    setShowSearchStudent(true);
    props.handleClearTimeout();
  };

  const handleCloseAddStudentModal = () => {
    setShowAddStudentModal(false);
    props.handleSetTimeOutID();
  };

  const handleCloseSearchStudentModal = () => {
    setShowSearchStudent(false);
    props.handleSetTimeOutID();
  };

  handleChangeStudents();

  if (students_list.length > 0) list = students_list.map(el =>
      <div className="display-8 m-2 p-3 cursor-pointer w-100 border border-secondary" onClick={() => selectStudent(el)} key={el.student.uuid}>
        {el.student.title}
      </div>);
  
  return (
    <>
      {props.isSessionStart
        ? <div className='Screen container'>
          <div className='d-flex flex-column justify-content-start align-items-center h-100'>
            <h1 className='text-center display-5 m-4 font-weight-normal'>{course_title}</h1>
            <h1 className='text-center display-5 m-2'>{props.t('Select yourself')}</h1>
            <FormGroup className='search-emp__name-container text-center m-4 d-flex w-50'>
              <FormControl
                type='text'
                autoComplete='off'
                onChange={(e) => setSearchString(e.target.value)}
                id='controlName'
                name='name'
                className={`search-emp__name-field mx-auto border-secondary form-control`}
                placeholder={props.t('Search by last name')}
                value={searchString} />
            </FormGroup>
            <Content className={`d-flex flex-column justify-content-center align-items-center text-center`}>
              {students_list.length > 0
                ? list
                : <h4 className={`preshift__text text-center Text_danger`}>
                    {props.t(props.network === 'Network Error' ? 'Network Error' : 'Empty list of students')}
                  </h4>}
            </Content>
            <Button className='MuiButton Btn Btn_success mb-5' onClick={handleShowAddStudentModal}>
              <FontAwesomeIcon className='mr-2' icon={faPlus} />
              {props.t('Add a student')}
            </Button>
          </div>
        </div>
      : <div className='Screen container' style={{"display":"flex", "flex-direction":"column", "align-content":"space-between","justify-content":"space-evenly"}}>
          <div >
            <h4 className={`preshift__text text-center ${props.network === 'Network Error' ? 'Text_danger' : 'Text_success'}`}>              
                {props.t('This instance of the software is running in classroom mode.')}
            </h4>
            <h5 style={{"margin-top":"20px", fontSize:"20px"}} className="text-center preshift_text ">
              {props.t('No training session scheduled')} 
              <br/>{props.t('You can start learning outside of the training session.')}</h5>
          </div>
          <div style={{"justify-content":"center","display": "flex"}}>
            <Button style={{"padding":"35px",fontSize:"30px"}} className="MuiButton Btn Btn_success btn btn-primary text-center"  onClick={handleShowAddStudentModal} >
              <span className="text-center">{props.t('Start learning')}</span>
              </Button>
          </div>
        </div>}
      {showSelectedStudent && props.selected_student.student?.title &&
        <ConfirmStudentModal
          t={props.t}
          theme={props.app.theme_name}
          title={props.selected_student.student.title}
          selectStudent={selectStudent}
          toElectronicCourses={props.toElectronicCourses} />}
      {showAddStudentModal &&
        <AddStudentModal
          t={props.t}
          theme={props.app.theme_name}
          free_course={props.free_course}
          course={props.course}
          courses_for_choice={props.courses_for_choice}
          handleSelectCourse={props.handleSelectCourse}
          handleCloseAddStudentModal={handleCloseAddStudentModal}
          setStudent={props.setStudent}
          handleGetPositionsOrStructures={props.handleGetPositionsOrStructures} />}
      {showSearchStudent &&
        <SearchStudentModal
          t={props.t}
          theme={props.app.theme_name}
          free_course={props.free_course}
          course={props.course}
          courses_for_choice={props.courses_for_choice}
          handleSelectCourse={props.handleSelectCourse}
          handleCloseSearchStudentModal={handleCloseSearchStudentModal}
          handleSelectStudent={props.handleSelectStudent}
          setStudent={props.setStudent}
          showAddFromSearch={showAddFromSearch}
          handleGetStudentByNameOrTabel={props.handleGetStudentByNameOrTabel}
          sessionStart = {props.isSessionStart} />}
    </>
  );
}