import React, { useEffect, useState } from 'react';
import { Button, FormControl, FormLabel, FormGroup, Spinner } from 'react-bootstrap';
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SearchStudentModal(props) {
  const [searchValue, setSearchValue] = useState('');
  const [inputName, setInputName] = useState('tabel'); // 'tabel' | 'title';
  const [showSpinner, setShowSpinner] = useState(false);
  const [resultArray, setResultArray] = useState([]);
  const [course, setCourse] = useState('');
  const [showCourses, setShowCourses] = useState(false);
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);
  const [error, setError] = useState('');
  const courses = props.courses_for_choice;
  const sessionStart = props.sessionStart??false;

  useEffect(() => {
    let bool = false;
    if (props.free_course && !props.course?.uuid && !course) bool = true;
    setIsDisabledBtn(bool);
  }, [course]);

  const handleChangeSearchValue = (e) => {
    if (inputName === 'title') {
        setSearchValue(e.target.value);
    } else {
        const value = +e.target.value;
        if (value > 0) setSearchValue(value);
        if (value === 0) setSearchValue('');
    }
  };

  const searchStudent = () => {
    setResultArray([]);
    setSearchValue('');
    if (!showSpinner) {
        props.handleGetStudentByNameOrTabel(inputName, searchValue, setShowSpinner, setResultArray, setError);
    };
  };

  const handleChangeInputName = (name) => {
    setInputName(name);
    setSearchValue('');
  };

  const handleSelectCourse = (el) => {
    props.handleSelectCourse(el.uuid);
    setCourse(el.title);
    setShowCourses(false);
  };
  if(courses.length==1 && course == '')
    {
      let course = courses[0];
      props.handleSelectCourse(course.uuid);
      setCourse(course.title);
      setShowCourses(false);
    }
  return (
    <div className={`${props.theme}`}>
      <div className={`screensaver_div active`}>
        <div className='screensaver_modal w-75' style={{ maxWidth: '700px', maxHeight: '80%' }}>
          <div className="">
            <h1 className="text-center text-light">{props.t('Search employee')}</h1>
            <div className="course_modal_x display-7 text-light cursor-pointer"
                style={{height: '5rem', width: '5rem'}}
                onClick={props.handleCloseSearchStudentModal}>
              <FontAwesomeIcon className='m-2' icon={faClose} />
            </div>
          </div>
          <div className='d-flex justify-content-center w-100'>
            <div className='display-7 text-center text-dark w-100 py-2'
                style={{ backgroundColor: inputName === 'tabel' ? '#C0C0C0' : 'white' }}
                onClick={() => handleChangeInputName('tabel')}>
              {props.t('By personal number')}
            </div>
            <div className='display-7 text-center text-dark w-100 py-2'
                style={{ backgroundColor: inputName === 'title' ? '#C0C0C0' : 'white' }}
                onClick={() => handleChangeInputName('title')}>
              {props.t('By full name')}
            </div>
          </div>        
            
          {courses.length > 0 && 
            <>
              <FormLabel className='display-7 mt-4' style={{ color: 'white' }}>{props.t('Course name')}</FormLabel>
              <FormControl
                type='text'
                required
                onChange={()=>{}}
                onClick={() => setShowCourses(!showCourses)}
                name='Course'
                autoComplete='off'
                isInvalid={isDisabledBtn}
                placeholder={props.t('Course name')}
                className={`search-emp__name-field mx-auto form-control`}
                style={{ backgroundColor: 'white', height: '5rem' }}
                value={course} />
              {showCourses && courses.map(el =>
                <span className={`display-9 border p-3 dropDownStyle w-100 text-center `} key={el.uuid} onClick={() => handleSelectCourse(el)} >
                  {el.title}
                </span>)}
            </>}
          <FormGroup className='text-center m-4 w-100 d-flex flex-column'>
            <div className='d-flex justify-content-between w-100'>
              <FormControl
                  type='text'
                  onChange={handleChangeSearchValue}
                  id='searchStudentInput'
                  name='searchStudentInput'
                  autoComplete='off'
                  placeholder={props.t(inputName === 'tabel' ? 'Entry of personnel' : 'Enter your full name')}
                  className={`search-emp__name-field mx-auto form-control`}
                  style={{ backgroundColor: 'white', height: '5rem' }}
                  value={searchValue} />
              <Button className='MuiButton Btn Btn_success ml-3' onClick={searchStudent} disabled={isDisabledBtn}>
                {props.t('Find')}
              </Button>
            </div>
          </FormGroup>
          {showSpinner
            ? <Spinner className='m-4' size='lg' animation='border' as='span' variant='light' />
            : (resultArray.length > 0 &&
                <div className='d-flex flex-column m-2 w-100 overflow-auto'>
                  {resultArray.map(el =>
                    <div key={el.uuid} onClick={() => props.setStudent({uuid: el.uuid})}
                        className={`d-flex flex-column align-items-start display-9 border p-3 dropDownStyle`}>
                      <span><b>{el.title}</b></span>
                      <span>{el.structure}</span>
                    </div>)}
                </div>)}
          {error && <h1 className="text-center text-danger">{error}</h1>}
          <div className='d-flex justify-content-center w-100 mt-4'>
            <Button className='MuiButton Btn Btn_success' onClick={props.showAddFromSearch}>
              {props.t('Add a new one')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
