import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from "redux";
import { isElectron } from 'react-device-detect';
import {withTranslation} from 'react-i18next';
import i18n from "i18next";
import {CSSTransition} from 'react-transition-group';
import {HashRouter, Route, Switch, withRouter} from 'react-router-dom';
import PrivateRoute from './components/containers/PrivateRoute';
import Header from "./components/blocks/Header";
import CabinetContainer from "./components/containers/CabinetContainer";
import FeedbackListContainer from "./components/containers/feedback/FeedbackListContainer";
import FeedbackAnswerContainer from "./components/containers/feedback/FeedbackAnswerContainer";
import FailVisual from "./components/screens/questions/FailVisual";
import SuccessVisual from "./components/screens/questions/SuccessVisual";
import Fail from "./components/screens/questions/Fail";
import Success from "./components/screens/questions/Success";
import Preloader from "./components/blocks/Preloader";
import StartupStepper from "./components/steppers/StartupStepper";
import QuestionStepper from "./components/steppers/QuestionStepper";
import FatalError from "./components/screens/FatalError";
import Modal from "./components/modals/Modal";
import SelectLang from "./components/modals/SelectLang";
import appManage from "./reducers/app-manager";
import {getLocaleId} from "./reducers/locale-selector";
import ErrorBoundary from "./ErrorBoundary";
import FeedbackDialogContainer from "./components/containers/feedback/FeedbackDialogContainer";
import LibraryListContainer from "./components/containers/library/LibraryListContainer";
import LibraryMaterialContainer from "./components/containers/library/LibraryMaterialContainer";
import NoticesStepper from "./components/steppers/NoticesStepper";
import NoticeListContainer from "./components/containers/NoticeListContainer";
import EmpLvlContainer from "./components/containers/EmpLvlContainer";
import SettingsInfoContainer from './components/containers/SettingsInfoContainer';
import ScreensaverContainer from './components/containers/ScreensaverContainer';
import SizContainer from './components/containers/SizContainer';
import DemoQuestionsContainer from './components/containers/DemoQuestionsContainer';
import ExtraEducationsContainer from './components/containers/questions/ExtraEducationsContainer';
import DemoNoticeContainer from './components/containers/DemoNoticeContainer';
import DemoQuestionPageContainer from './components/containers/DemoQuestionPageContainer';
import ElectronicCoursesContainer from './components/containers/ElectronicCourses/ElectronicCourseContainer';
import SetElectronSettingsContainer from './components/containers/SetElectronSettingsContainer';
import QuestionnairesListContainer from './components/containers/QuestionnairesListContainer';
import { ShowNotify } from './components/blocks/Notify';
import { getIsUpdated, startSecondApp } from './helpers/functions';
import SetRfidCardContainer from './components/containers/SetRfidCardContainer';
import configApp from "./config/config";
import { config } from 'process';

class App extends Component {
    constructor(props) {
        super(props);
        i18n.init();

        this.state = {
            keyRender: null,
            mounted: false,
        }
    }

    componentDidMount() {
        this.setState({
            keyRender: Math.random(),
            mounted: true,
        });
        if (isElectron) {
            const isUpdated = getIsUpdated();
            isUpdated && startSecondApp();
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.localeId !== this.props.localeId) this.setState({keyRender: Math.random()});
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) this.setState({prevPath: this.props.location})
    }

    render() {
        let Components;
              
        if (!this.props.app.initialized && !this.props.app.isElectronStartAppError) {
            Components = <Preloader network_error={this.props.network_error}/>
        } else if (!this.props.app.initialized && this.props.app.isElectronStartAppError && isElectron) {
            Components = <Switch><Route path='*' exact={true} component={SetElectronSettingsContainer}/></Switch>
        } else if (this.props.app.errors && this.props.app.not_fatal_errors.length < 1) {
            Components = <Switch><Route path='*' exact={true} component={FatalError}/></Switch>
        } else {

            let zoomValue = 100;
            if(configApp.device_settings.server_zoom_priority??true)
            {
                zoomValue = this.props.app.device_zoom;

            }else{
                zoomValue = configApp.device_settings.default_zoom;
            }
            appManage.setZoom(zoomValue);
            Components = <>
                <Header/>
                <Switch>
                    <PrivateRoute path="/cabinet" exact={true} component={CabinetContainer}/>
                    <PrivateRoute path="/library/:types" exact={true} component={LibraryListContainer}/>
                    <PrivateRoute path="/library/material/:id" exact={true} component={LibraryMaterialContainer}/>
                    <PrivateRoute path="/feedback" exact={true} component={FeedbackListContainer}/>
                    <PrivateRoute path="/feedback/add" exact={true} component={FeedbackAnswerContainer}/>
                    <PrivateRoute path="/feedback/dialog/:id" exact={true} component={FeedbackDialogContainer}/>
                    <PrivateRoute path="/siz" exact={true} component={SizContainer}/>
                    <PrivateRoute path="/preshift" exact={true} component={QuestionStepper}/>
                    <PrivateRoute path="/extra-education" exact={true} component={ExtraEducationsContainer}/>
                    <PrivateRoute path="/electronic-courses" exact={true} component={ElectronicCoursesContainer}/>
                    <PrivateRoute path="/questionary" exact={true} component={QuestionnairesListContainer}/>
                    <PrivateRoute path="/failvisual" exact={true} component={FailVisual}/>
                    <PrivateRoute path="/successvisual" exact={true} component={SuccessVisual}/>
                    <PrivateRoute path={"/fail"} exact={true} component={Fail}/>
                    <PrivateRoute path={"/success"} exact={true} component={Success}/>
                    <PrivateRoute path={"/notices"} exact={true} component={NoticesStepper}/>
                    <PrivateRoute path={"/notice-list"} exact={true} component={NoticeListContainer}/>
                    <PrivateRoute path={"/notice-list/:id"} exact={true} component={NoticeListContainer}/>
                    <PrivateRoute path={"/emp-lvl"} exact={true} component={EmpLvlContainer}/>
                    <PrivateRoute path={"/set-rfid"} exact={true} component={SetRfidCardContainer}/>
                    <Route path="/electronic-courses-class" exact={true} component={ElectronicCoursesContainer}/>
                    <Route path={"/demo"} exact={true} component={DemoQuestionsContainer}/>
                    <Route path={"/demo-notification/*"} exact={true} component={DemoNoticeContainer}/>
                    <Route path={"/demo-question/*"} exact={true} component={DemoQuestionPageContainer}/>
                    <Route path='/error' exact={true} component={FatalError}/>
                    <Route path='*' exact={true} component={StartupStepper}/>
                </Switch>
                <Modal><SelectLang/></Modal>
            </>
        }

        const AnimatedSwitch = withRouter(({location}) => (
            <>
                <div className={`d-flex flex-column h-100 w-100 ${this.props.app.theme_name}`}>
                    <ErrorBoundary>
                        {Components}
                    </ErrorBoundary>
                </div>
            </>
        ));

        return <>
            <CSSTransition in={this.state.mounted} key={this.state.keyRender} classNames="slide" timeout={500}>
                <HashRouter keyRender={this.state.keyRender}>
                    <AnimatedSwitch/>
                </HashRouter>
            </CSSTransition>
            <ScreensaverContainer />
            <SettingsInfoContainer />
            <ShowNotify />
        </>;
    }
}

const mapStateToProps = (store) => {
    return {
        app: (store.hasOwnProperty('appState')) ? store.appState : null,
        network_error: (store.hasOwnProperty('networkAnswer')) ? store.networkAnswer.network_error : '',
        localeId: getLocaleId(store),
    }
};

export default compose(
    connect(null, appManage.setDefaultStateApp),
    connect(mapStateToProps, appManage.initializeApp)
)(withTranslation()(App));